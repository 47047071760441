import { createRouter, createWebHistory } from "vue-router";
import LoginForm from "./components/LoginForm.vue";
import JobsList from "./components/JobsList.vue";
import ParseOffer from "./components/offers/ParseOffer.vue";
import ProfileForm from "./components/ProfileForm.vue";
import OffersList from "./components/offers/OffersList.vue";
import MyOffersList from "./components/offers/MyOffersList.vue";
import ItemsList from "./components/items/ItemsList.vue";

const routes = [
  {
    path: "/",
    component: LoginForm,
    meta: { showSidebar: false, title: "Offers Alert" },
  },
  {
    path: "/jobs",
    component: JobsList,
    meta: { showSidebar: true, title: "Offers Alert" },
  },
  {
    path: "/offers",
    component: OffersList,
    meta: { showSidebar: true, title: "Offers Alert" },
  },
  {
    path: "/myoffers",
    component: MyOffersList,
    meta: { showSidebar: true, title: "Offers Alert" },
  },
  {
    path: "/parse-offer",
    component: ParseOffer,
    meta: { showSidebar: true, title: "Offers Alert" },
  },
  {
    path: "/profile",
    component: ProfileForm,
    meta: { showSidebar: true, title: "Offers Alert" },
  },
  {
    path: "/items",
    component: ItemsList,
    meta: { showSidebar: true, title: "Offers Alert" },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
