<template>
  <div>

    <!-- Contenu de la page -->
    <LoaderDiv :isLoading="loading" loaderText="Chargement en cours, veuillez patienter..." />

    <h2>Mes objets</h2>
    <div>
      Cette écran affiche la liste des objets que vous détenez
    </div>
    <div class="count">Vous avez {{ totalItems }} objets.</div>

    <div class="main-content-items" ref="mainContentItems" @scroll="handleScroll">
    <table>
      <thead>
        <tr>
          <th>Marque</th>
          <th>Modèle</th>
          <th>Titre</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody ref="itemsTable">
        <tr v-for="item in items" :key="item._key">
          <td>{{ item.title }}</td>        
          <td>{{ item.brand }}</td>
          <td>{{ item.model }}</td>
          <td><button @click="parseItem(item)">Modifier</button></td>
        </tr>
      </tbody>
    </table>
    </div>  
  </div>
</template>

<script>
import axios from "axios";
import config from "@/config/config.js";
import { isTokenExpired } from "../../auth";
import LoaderDiv from "./../LoaderDiv.vue"; // Chemin vers votre composant Loader

export default {
  components: {
    LoaderDiv
  },
  name: "ItemsList",
  data() {
    return {
      loadingIndex: null,
      items: [],
      loading: true,
      loadingPopup: false,
      selectedItem: {},
      isModalVisible: false,
      currentPage: 0,
      limit: config.itemsLimit,
      cdnUrl: config.cdnUrl,      
      totalItems: 0,
      allItemsLoaded: false,      
    };
  },
  async mounted() {

    const token = localStorage.getItem("access_token");
    if (isTokenExpired(token)) {
      this.$router.push("/");
    }
    await this.loadItems();
  },
  methods: {
    async loadItems() {

      const token = localStorage.getItem("access_token");
      if (isTokenExpired(token)) {
        this.$router.push("/");
      }

      try {
        // Activer le loader
        this.loading = true;

        const response = await axios.get(
          `${config.apiUrl}/items?page=${this.currentPage}&limit=${this.limit}`
        );

        // Append new results
        this.items.push(...response.data.result);

        // Get statistics
        this.totalItems = response.data.total_items;
        this.currentPage += 1;

        // Vérifier si tous les éléments ont été chargés
        if (this.items.length >= this.totalItems) {
          this.allItemsLoaded = true;
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des offres:", error);
      } finally {
        this.loading = false;
      }
    },

    // Gestion du scroll infini
    handleScroll() {
      const mainContent = this.$refs.mainContentItems;
      const itemsTable = this.$refs.itemsTable;

      if (!mainContent || !itemsTable || this.allItemsLoaded) return;

      // Vérifier si l'utilisateur a atteint la fin du tableau (tbody)
      const tableBottomReached =
        mainContent.scrollTop + mainContent.clientHeight >= itemsTable.clientHeight;

      if (tableBottomReached) {
        this.loadItems(); // Charger plus d'éléments quand on arrive à la fin du tableau
      }
    },
  }
};
</script>

<style scoped>
.count {
  margin-top: 10px;
  font-size: 11pt;
  font-weight: bold
}

.thumbnail {
  float: left;
  margin: 0px 15px 0px 0px;
  max-height: 37px;
}

.ad-image {
  width: 100%;
  max-width: 400px;
  height: auto;
  margin: 10px 0;
}

.items-list-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* Prendre toute la hauteur de la fenêtre */
}

.main-content-items {
  width: auto;
  height: calc(100vh - 160px);
  /* flex-grow: 1; */
  /* Ajustez la hauteur pour permettre le défilement */
  overflow-y: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
}

th {
  /* background-color: #f2f2f2; */
  font-weight: normal;
  background-color: #3498db;
  color: white;
}

button {
  padding: 6px 12px;
  background-color: #ff4d4d;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #ff1a1a;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  text-align: center;
  font-size: 18px;
  color: #3498db;
}
</style>
