<template>
  <div class="modal-backdrop" v-if="isVisible" @keydown.esc="handleEscapeKey" tabindex="0">
    <div class="modal-content">
      <!-- Bouton en forme de croix pour fermer la popup -->
      <button class="close-btn" @click="close">×</button>

      <!-- Informations supplémentaires -->
      <template v-if="loadingPopup">
        <p>Chargement...</p>
      </template>
      <template v-else>

        <!-- Miniatures des images -->
        <div class="thumbnails">
          <img v-for="(image, index) in offer.images" :key="index"
            :src="cdnUrl + '/images/' + offer._key + '/' + image.split('/').pop()" class="thumbnail"
            @click="setCurrentImage(index)" :class="{ selected: index === currentImageIndex }" alt="Miniature" />
        </div>

        <!-- Contenu du modal (images, etc.) -->
        <div class="large-image-container" v-if="currentImageIndex !== null">
          <img :src="cdnUrl + '/images/' + offer._key + '/' + offer.images[currentImageIndex].split('/').pop()"
            class="large-image" alt="Image en grand" @click="closeImageView">
        </div>

        <!-- Inclure la popup pour gérer les objets -->
        <OfferManagePopup :visible="isManageOfferPopupVisible" :items="offerItems" :offer_id="offer._key"
          @close="isManageOfferPopupVisible = false" />

        <OfferEditPopup :visible="isEditOfferPopupVisible" :offer="offer" @close="isEditOfferPopupVisible = false"
          @save="updateOffer" />

        <div v-if="currentImageIndex == null">
          <h2>{{ offer.title }} - <a :href="offer.url" target="_blank">Yahoo !</a> / <a target="_blank"
              :href="'https://zenmarket.jp/fr/auction.aspx?itemCode=' + offer._key">Zenmarket</a></h2>
          <!-- <p><button v-show="offer.owns" @click="openManageOfferPopup" class="button">Gérer les objets de
              l'annonce</button> -->
          <p>
            <button v-show="offer.owns" @click="openManageOfferPopup" :disabled="loadingItem" class="button">
              <span v-if="loadingItem">Chargement...</span>
              <span v-else>Gérer les objets de l'annonce</span>
            </button>&nbsp;
            <button v-show="offer.owns" @click="openEditOfferPopup" class="button">
              Détail de l'offre
            </button>&nbsp;
            <button @click="close" class="button button-close">
              Fermer
            </button>
          </p>
          <p><strong>Prix:</strong> {{ offer.price }} &#165;</p>
          <p><strong>Dernière mise à jour :</strong> {{ offer.timestamp_user }} (utc: {{ offer.timestamp_utc }})</p>
          <p><strong>Date de début:</strong> {{ offer.starttime_user }} (locale: {{ offer.starttime }})</p>
          <p><strong>Date de fin:</strong> {{ offer.endtime_user }} (locale: {{ offer.endtime }})</p>
          <p v-html="offer.html_description || offer.description"></p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import config from '@/config/config.js';
import OfferManagePopup from './OfferManagePopup.vue';
import OfferEditPopup from './OfferEditPopup.vue';
import axios from 'axios';

export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    offer: {
      type: Object,
      default: () => ({})
    },
    loadingPopup: {
      type: Boolean,
      default: false
    }
  },
  components: {
    OfferManagePopup,
    OfferEditPopup
  },
  data() {
    return {
      currentImageIndex: null, // Index de l'image actuellement affichée en grand
      isManageOfferPopupVisible: false,
      isEditOfferPopupVisible: false,
      cdnUrl: config.cdnUrl,
      offerItems: [],
      loadingItem: false
    };
  },
  methods: {
    async updateOffer(updatedOffer) {
      let cost = 0;
      for (var name in updatedOffer) {
        if (name != "totalCost")
          cost += isNaN(updatedOffer[name]) ? 0 : Number(updatedOffer[name]);
      }
      updatedOffer["totalCost"] = Number(cost.toFixed(2))
      try {
        const response = await axios.post(`${config.apiUrl}/offers/${this.offer._key}`,
          JSON.stringify({ "transactionCosts": updatedOffer }),
          { headers: { 'Content-Type': 'application/json' } });
        if (!response.status === 200) {
          throw new Error('Erreur lors de la mise à jour');
        }
      } catch (error) {
        console.error('Erreur lors de la mise à jour: ', error);
        return;
      }
      this.isEditOfferPopupVisible = false;
      this.$emit('updateCost', cost); // Émettre l'objet modifié au parent

    },
    openEditOfferPopup() {
      this.isEditOfferPopupVisible = true;
    },
    openManageOfferPopup() {
      this.loadItems();
    },
    closeManageOfferPopup() {
      this.isManageOfferPopupVisible = false;
    },
    close() {
      this.currentImageIndex = null;
      this.isManageOfferPopupVisible = false;
      this.offerItems = [];
      this.$emit('close');
    },
    setCurrentImage(index) {
      this.currentImageIndex = index;
    },
    prevImage() {
      if (this.currentImageIndex > 0) {
        this.currentImageIndex--;
      }
    },
    nextImage() {
      if (this.currentImageIndex < this.offer.images.length - 1) {
        this.currentImageIndex++;
      }
    },
    handleEscapeKey(event) {
      if (event.key === 'Escape') {
        if (this.currentImageIndex !== null) {
          event.stopPropagation(); // Empêche la fermeture de la popup          
          this.closeImageView(); // Ferme l'image en grand si une image est affichée
        } else {
          this.close(); // Ferme la popup si aucune image n'est affichée en grand
        }
        this.isManageOfferPopupVisible = false;
        this.isEditOfferPopupVisible = false;
      }
    },
    handleArrowKeys(event) {
      if (event.key === 'ArrowLeft') {
        this.prevImage(); // Passe à l'image précédente avec la flèche gauche
      } else if (event.key === 'ArrowRight') {
        this.nextImage(); // Passe à l'image suivante avec la flèche droite
      }
    },
    closeImageView() {
      // Ferme l'affichage de l'image en grand en cliquant dessus
      this.currentImageIndex = null;
    },
    async loadItems() {
      this.loadingItem = true;
      try {
        // Appel à l'API pour récupérer les détails de l'élément
        const response = await axios.get(
          `${config.apiUrl}/offers/${this.offer._key}/items`
        );
        this.offerItems = response.data.result;
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des items de l'offre :",
          error
        );
      } finally {
        this.isManageOfferPopupVisible = true;
        this.loadingItem = false;
      }
    },
  },
  mounted() {
    window.addEventListener('keydown', this.handleEscapeKey);
    window.addEventListener('keydown', this.handleArrowKeys);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleEscapeKey);
    window.removeEventListener('keydown', this.handleArrowKeys);
  }
};
</script>

<style scoped>
.button {
  background-color: #3498db;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.button-close {
  background-color: #e74c3c;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  /* Positionnement relatif pour placer la croix */
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 60%;
  max-width: 800px;
  max-height: 80%;
  overflow-y: auto;
}

/* Style du bouton de fermeture en forme de croix */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #555;
}

.close-btn:hover {
  color: #e74c3c;
}

/* Autres styles inchangés */
.large-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.large-image {
  width: 100%;
  max-width: 600px;
  height: auto;
  margin-bottom: 10px;
}

.image-controls {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.thumbnails {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  gap: 10px;
  margin-top: 20px;
  justify-items: center;
}

.thumbnail {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-right: 10px;
  cursor: pointer;
  border: 2px solid transparent;
}

.thumbnail.selected {
  border-color: #3498db;
}
</style>