<template>

  <div class="sidebar">
    <h2 style="color:white">Bid Alert</h2>
    <ul>
      <li>
        <router-link to="/jobs">
          <font-awesome-icon :icon="['fas', 'briefcase']" />
          <span>Alertes</span>
        </router-link>
      </li>
      <li>
        <router-link to="/offers">
          <font-awesome-icon :icon="['fas', 'fa-gamepad']" />
          <span>Offres</span>
        </router-link>
      </li>
      <li>
        <router-link to="/parse-offer">
          <font-awesome-icon :icon="['fas', 'fa-download']" />
          <span>Nouvel offre</span>
        </router-link>
      </li>
    </ul>


    <h2 style="color:white">Mes objets</h2>
    <ul>
      <li>
        <router-link to="/myoffers">
          <font-awesome-icon :icon="['fas', 'id-card']" />
          <span>Mes offres détenues</span>
        </router-link>
      </li>
      <li>
        <router-link to="/items">
          <font-awesome-icon :icon="['fas', 'id-card']" />
          <span>Mes objets</span>
        </router-link>
      </li>      
    </ul>

    <h2 style="color:white">Mes informations</h2>
    <ul>
      <li>
        <router-link to="/profile">
          <font-awesome-icon :icon="['fas', 'id-card']" />
          <span>Profile</span>
        </router-link>
      </li>
    </ul>
    <div style="margin-left:10px">
      <a @click="logout">
        <font-awesome-icon :icon="['fas', 'sign-out-alt']" />
        <span>Déconnexion</span>
      </a>
    </div>
    <!-- Lien de déconnexion en bas -->
    <div class="logout">
      <!-- <a @click="logout">
        <font-awesome-icon :icon="['fas', 'sign-out-alt']" />
        <span>Déconnexion</span>
      </a> -->
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBriefcase, faFileAlt, faSignOutAlt, faFolderOpen, faGamepad, faIdCard, faDownload } from '@fortawesome/free-solid-svg-icons';

// Ajouter les icônes à la bibliothèque
library.add(faBriefcase, faFileAlt, faSignOutAlt, faFolderOpen, faGamepad, faIdCard, faDownload);

export default {
  components: {
    FontAwesomeIcon
  },
  methods: {
    logout() {
      // Suppression des tokens du localStorage
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      // Redirection vers la page de login
      this.$router.push('/');
    }
  }
};
</script>

<style scoped>
.logo {
  width: 100px
}

.sidebar {
  width: 200px;
  background-color: #2c3e50;
  height: 100vh;
  padding: 20px;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 20px;
  display: block;
}

a {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
}

a:hover {
  color: #3498db;
}

span {
  margin-left: 10px;
}

svg {
  font-size: 20px;
}

.logout {
  margin-top: auto;
}
</style>
