<template>
    <div v-if="visible" class="popup">
        <div class="popup-content">
            <h2>Informations sur la transaction</h2>

            <!-- Formulaire pour ajouter un nouvel objet -->
            <div class="form-section">
                <h3>Détail de l'achat</h3>

                <table width="160" border="0">
                    <tbody>
                    <tr>
                        <td>
                            <label>Prix d'achat :</label>
                            <input v-model.number="localOffer.price" placeholder="Prix d'achat" class="input-field">€                            
                        </td>
                        <td>
                            <label>Commission:</label>
                            <input v-model.number="localOffer.commission" placeholder="Commission" class="input-field">€             
                        </td>   
                        <td>
                            <label>TVA :</label>
                            <input v-model.number="localOffer.vat" placeholder="TVA" class="input-field">€
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>Livraison locale :</label>
                            <input v-model.number="localOffer.localShipping" placeholder="Livraison locale" class="input-field">€                            
                        </td>
                        <td>
                            <label>Emballage :</label>
                            <input v-model.number="localOffer.package" placeholder="Cout d'emballage" class="input-field">€                            
                        </td>
                        <td>
                            <label>Frais de port :</label>
                            <input v-model.number="localOffer.shipping" placeholder="Frais de port" class="input-field">€                            
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>Frais d'import :</label>
                            <input v-model.number="localOffer.taxes" placeholder="Taxes" class="input-field">€
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>                        
                    </tr>
                    </tbody>                    
                </table>
               
            </div>

            <div class="actions">
                <button @click="saveOffer" class="btn-save">Enregistrer et fermer</button>
                <button @click="closePopup" class="btn-close">Fermer</button>
            </div>
        </div>
    </div>
</template>

<script>
import { isTokenExpired } from '@/auth';

export default {
    props: {
        visible: Boolean,
        offer: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            localOffer: {
                price:0,
                vat:0,
                localShipping:0,
                package:0,
                shipping:0,
                taxes:0,
                commission:0
            },
            isEditing: false,
            editingIndex: -1,
            highlightedIndex: -1
        };
    },
    created() {
        this.localOffer = { ...this.offer.transactionCosts };
    },    
    // watch: {
    //     items: {
    //         handler(newAd) {
    //             if (newAd) {
    //                 this.localItems = newAd; // Charger les objets depuis l'annonce
    //             }
    //         },
    //         immediate: true
    //     }
    // },
    methods: {
        saveOffer() {
            this.localOrder = {}
            this.$emit('save', this.localOffer);
            this.$emit('close');            
        },
        resetForm() {
            this.newItem = { id: null, brand: '', model: '', condition: '', description: '' };
            this.isEditing = false;
            this.editingIndex = -1;
        },
        closePopup() {
            this.localOrder = {}
            this.$emit('close');
        }
    },
    mounted() {
        const token = localStorage.getItem("access_token");
        if (isTokenExpired(token)) {
            this.$router.push("/");
        }
    }
};
</script>

<style scoped>

td {
  text-align:right
}

.input-field.is-invalid {
    border-color: red;
}

.error-message {
    color: red;
    font-size: 12px;
    display: block;
    margin-top: -13px;
    margin-bottom: 15px;
}

H3 {
    margin: 0px;
    margin-bottom: 10px;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-content {
    background-color: #fff;
    padding: 20px;
    width: 800px;
    border-radius: 8px;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.item-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.item-details {
    flex: 1;
}

.divider {
    margin: 20px 0;
    border: 0;
    height: 1px;
    background: #ddd;
}

.form-section {
    margin-bottom: 20px;
}

.checkbox-field {
    /* width: 100%; */
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.input-field {
    margin-left:10px;
    text-align: right;
    width: 50px;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.btn-add,
.btn-save,
.btn-close,
.btn-edit,
.btn-remove {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
}

.btn-remove {
    background-color: #dc3545;
}

.btn-add:hover,
.btn-save:hover,
.btn-close:hover,
.btn-remove:hover {
    opacity: 0.9;
}

.actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}
</style>