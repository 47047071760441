<template>
  <div>

    <!-- Contenu de la page -->
    <LoaderDiv :isLoading="loading" loaderText="Chargement en cours, veuillez patienter..." />

    <h2>Mes offres détenues</h2>
    <div>
      Cette écran affiche la liste des offres que vous détenez
    </div>
    <div class="count">Vous avez {{ totalOffers }} offres.</div>

    <div class="main-content-offers" ref="mainContentOffers" @scroll="handleScroll">
    <table>
      <thead>
        <tr>
          <th>Détail</th>
          <th width="80">Cout</th>          
          <th width="140">Date de fin</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody ref="offersTable">
        <tr v-for="offer, index in offers" :key="offer._key">
          <td>
            <a href="#" @click.prevent="showDetails(offer, index)"> <img
                :src="cdnUrl + '/images/' + offer._key + '/' + offer._key + '_thumbnail.jpg'" width="50px"
                class="thumbnail"></a>
            {{ offer.title }}<br />
            <a href="#" @click.prevent="showDetails(offer, index)">{{ offer._key }}</a> / <a target="_blank"
              :href="'https://page.auctions.yahoo.co.jp/jp/auction/' + offer._key">Yahoo !</a> / <a target="_blank"
              :href="'https://zenmarket.jp/fr/auction.aspx?itemCode=' + offer._key">Zenmarket</a>
            <br>
          </td>
          <td>{{ offer.cost }}€</td>          
          <td>{{ offer.endtime }}</td>
          <td>
            <button @click="parseOffer(offer)">Rafraichir</button>
            &nbsp;
            <button @click="setOwned(offer)" :disabled="loadingIndex === offer._key">
              <span v-if="loadingIndex === offer._key">Chargement...</span>
              <span v-else>
                {{ offer.owns ? 'Perdu' : 'Acheté' }}
              </span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    </div>  

    <OfferDetailPopup :isVisible="isModalVisible" @close="isModalVisible = false" @updateCost="updateCost" :offer="selectedOffer"
      :loadingPopup="loadingPopup" />
      
  </div>
</template>

<script>
import axios from "axios";
import config from "@/config/config.js";
import { isTokenExpired } from "../../auth";
import LoaderDiv from "./../LoaderDiv.vue"; // Chemin vers votre composant Loader
import OfferDetailPopup from "./OfferDetailPopup.vue";
import { parse } from "@/common.js";

export default {
  components: {
    LoaderDiv,
    OfferDetailPopup,
  },
  name: "MyOffersList", // Ici, le nom du composant doit être multi-mots
  data() {
    return {
      loadingIndex: null,
      offers: [],
      loading: true,
      loadingPopup: false,
      selectedOffer: {},
      selectedIndex: -1,
      isModalVisible: false,
      currentPage: 0,
      limit: config.itemsLimit,
      cdnUrl: config.cdnUrl,      
      totalOffers: 0,
      allOffersLoaded: false,      
    };
  },

  async mounted() {

    const token = localStorage.getItem("access_token");
    if (isTokenExpired(token)) {
      this.$router.push("/");
    }
    await this.loadOffers();

  },

  methods: {
    updateCost(updatedCost){
      console.log(updatedCost)
      this.offers[this.selectedIndex].cost = updatedCost.toFixed(2)
    },

    async loadOffers() {
      const token = localStorage.getItem("access_token");
      if (isTokenExpired(token)) {
        // Rediriger vers la page de login si le token est expiré
        this.$router.push("/");
      }

      try {
        // Activer le loader
        this.loading = true;

        const response = await axios.get(
          `${config.apiUrl}/offers/owned?page=${this.currentPage}&limit=${this.limit}`
        );

        // Append new results
        this.offers.push(...response.data.result);

        // Get statistics
        this.totalOffers = response.data.total_items;
        this.currentPage += 1;

        // Vérifier si tous les éléments ont été chargés
        if (this.offers.length >= this.totalOffers) {
          this.allOffersLoaded = true;
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des offres:", error);
      } finally {
        // Désactiver le loader
        this.loading = false;
      }
    },

    // Gestion du scroll infini
    handleScroll() {
      const mainContent = this.$refs.mainContentOffers;
      const offersTable = this.$refs.offersTable;

      if (!mainContent || !offersTable || this.allOffersLoaded) return;

      // Vérifier si l'utilisateur a atteint la fin du tableau (tbody)
      const tableBottomReached =
        mainContent.scrollTop + mainContent.clientHeight >= offersTable.clientHeight;

      if (tableBottomReached) {
        this.loadOffers(); // Charger plus d'éléments quand on arrive à la fin du tableau
      }
    },

    async showDetails(offer, index) {
      // Affiche le loader
      this.loadingPopup = true;
      this.isModalVisible = true;
      this.selectedIndex = index;

      try {
        // Appel à l'API pour récupérer les détails de l'élément
        const response = await axios.get(
          `${config.apiUrl}/offers/${offer._key}`
        );

        // Met à jour selectedOffer avec les données récupérées de l'API
        this.selectedOffer = response.data;
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des détails de l'annonce :",
          error
        );
        // Gérer les erreurs ici (afficher un message d'erreur par exemple)
      } finally {
        // Désactiver le loader après la récupération des données
        this.loadingPopup = false;
      }
    },

    // Parse offer
    async parseOffer(offer) {
      this.loadingPopup = true;
      await parse(offer);
      this.loadingPopup = false;
    },
  },
};
</script>

<style scoped>
.count {
  margin-top: 10px;
  font-size: 11pt;
  font-weight: bold
}

.thumbnail {
  float: left;
  margin: 0px 15px 0px 0px;
  max-height: 37px;
}

.ad-image {
  width: 100%;
  max-width: 400px;
  height: auto;
  margin: 10px 0;
}

.offers-list-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* Prendre toute la hauteur de la fenêtre */
}

.main-content-offers {
  width: auto;
  height: calc(100vh - 160px);
  /* flex-grow: 1; */
  /* Ajustez la hauteur pour permettre le défilement */
  overflow-y: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
}

th {
  /* background-color: #f2f2f2; */
  font-weight: normal;
  background-color: #3498db;
  color: white;
}

button {
  padding: 6px 12px;
  background-color: #ff4d4d;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #ff1a1a;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  text-align: center;
  font-size: 18px;
  color: #3498db;
}
</style>
