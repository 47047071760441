// config.js
const config = {
  development: {
    apiUrl: "http://localhost:5000",
    cdnUrl: "https://cdn.prexens.com",
    itemsLimit: 50,
  },
  production: {
    apiUrl: "https://collection.prexens.com",
    cdnUrl: "https://cdn.prexens.com",
    itemsLimit: 50,
  },
};

// Déterminer l'environnement
const currentEnv = process.env.NODE_ENV || "development";

// Exporter la configuration correspondante
export default config[currentEnv];
